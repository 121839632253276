<template>
  <v-container
    id="scroll-target"
    grid-list-md
    pa-0
    relative
    class="attendence_view"
  >
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card class="page-container-card primary--border" outlined>
          <v-card-title class="title">
            Student Identity Card
            <v-spacer></v-spacer>
            <v-download-column-select
              :hide-download="true"
              option-type="radio"
              v-if="
                ($auth.isAdmin() ||
                  $auth.loggedInRole().slug === 'administrator') &&
                form.items.data.length > 0
              "
              :showPDF="false"
              :downloadColumns="card_designs"
              @selectedItem="selectedCardDesign"
            >
              SELECT TEMPLATE TYPE
            </v-download-column-select>
            <download-button
              color="primary"
              v-if="form.items.data.length"
              icon="print"
              @action="printStudentCard()"
              >PRINT BULK</download-button
            >
          </v-card-title>
          <v-card outlined>
            <v-card-title class="pa-0">
              <grade-section @onSectionChange="get"></grade-section>
              <!-- <v-flex xs2>
                <v-calendar-field
                  id-val="valid_date"
                  v-model="valid_date"
                  label="Valid Date"
                >
                </v-calendar-field>
              </v-flex> -->
            </v-card-title>
          </v-card>

          <v-data-table
            :headers="headers"
            :items="form.items.data"
            :options.sync="pagination"
            :footer-props="rowsPerPageItems"
            :server-items-length="form.items.meta.total"
            style="margin-top: 15px"
            :loading="form.loading"
          >
            <template v-slot:item="{ item, index }">
              <tr>
                <td>{{ index + form.items.meta.from }}</td>
                <td class="text-xs-left">
                  <v-list-item-avatar>
                    <img :src="item.profile_image" />
                  </v-list-item-avatar>
                </td>
                <td class="text-xs-left">
                  <strong v-if="pagination.sortBy === 'name'">{{
                    item.name
                  }}</strong>
                  <span v-else>{{ item.name }}</span>
                </td>
                <td class="text-xs-left">{{ item.personal.dob }}</td>
                <td class="text-xs-left">
                  {{ item.personal.gender | capitalize }}
                </td>
                <td class="text-xs-left">
                  {{ item.grade }} <sup>{{ item.section }}</sup>
                </td>
                <td class="text-xs-center">
                  {{ item.roll }}
                </td>
                <td class="text-right">
                  <!--<v-btn small icon color="primary" class="pa-0 ma-1"-->
                  <!--@click="printSingle(item.enroll_id)">-->
                  <!--<v-icon dark>print</v-icon>-->
                  <!--</v-btn>-->
                    <!-- @action="printSingle(item.enroll_id)" -->
                  <add-button
                    @action="printStudentCard(item.enroll_id)"
                    :permission="'section-read'"
                    icon="print"
                  >
                    Print
                  </add-button>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="validateDateDialogue" persistent max-width="290px">
      <v-card>
        <v-card-title class="primary white--text">
          <span class="title">Valid Till</span>
        </v-card-title>
        <v-card-text class="pb-1">
          <br />
            <v-text-field
              outlined
              label="Valid Till Date"
              v-model="valid_date"
              v-mask="'####-##-##'" 
              :rules="dateValidation"
              placeholder="YYYY-MM-DD"
              max-height="5px"
            />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small color="warning" outlined @click="validateDateDialogue = false,selectedUser='',validDate=''"
            >Close</v-btn
          >
          <v-btn
            small
            outlined
            color="success"
            v-if="print_status == 'single'"
            @click="printSingle()"
            >Print Single
          </v-btn>
          <v-btn small color="success" outlined @click="printBulk()" v-else
            >Print Bulk</v-btn
          >
          <!-- <v-btn small color="success" outlined @click="printBulk()" v-else
            >Print Bulk</v-btn
          > -->
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import Form from "@/library/Form";
import { cleanObj, validateDate,redirectDownloadUrl } from "../../../../library/helpers";
import Mixins from "@/library/Mixins";

const NepaliDate = require("nepali-date-js");
const nd = new NepaliDate();

export default {
	mixins: [Mixins],
	data: () => ({
		dateValidation: [(v) => validateDate(v) || "Invalid Date Format"],
		valid_date:"",
		validateDateDialogue:false,
		print_status:'single',
		selected_enroll_code:'',
		card_designs: [],
		id_card_template_id: "",
		defaultDesign: {},
		student_code: "",
		form: new Form({}, "/api/search-student"),
		search: "",
		batches: [],
		rowsPerPageItems: { "items-per-page-options": [5, 10, 25, 50, 75, 150] },
		// pagination: {
		//   rowsPerPage: 10,
		// },
   
		dateValidation: [(v) => validateDate(v) || "Invalid Date Format"],
		loading: true,
		headers: [
			{ text: "#", align: "left", value: "roll", width: 10, sortable: false },
			{
				text: "Photo",
				align: "left",
				value: "roll",
				width: 50,
				sortable: false,
			},
			{ text: "Name", align: "left", value: "name", width: 160 },
			{
				text: "Date of birth",
				align: "left",
				value: "dob",
				sortable: false,
				width: 100,
			},
			{
				text: "Gender",
				align: "left",
				value: "gender",
				sortable: false,
				width: 100,
			},
			{
				text: "Grade",
				align: "left",
				value: "grade",
				sortable: false,
				width: 100,
			},
			{
				text: "Roll",
				align: "center",
				value: "roll",
				width: "25",
				sortable: true,
			},
			{
				text: "Action",
				align: "right",
				value: "roll",
				sortable: false,
				width: 150,
			},
		],
		// section: '',
		// grade: '',
		// batchId: '',
		// sections: [],
		// grades: [],
		filter: false,
		// valid_date: nd.format("YYYY") + "-12-30",
		// studentRollNumbers: []
		exclude_types:[
			{value:'all',text:'Include All'},
			{value:'no_profile',text:'No profile Picture'}
		],
		exclude_type:'all',
		displaplay_exclude_type:false,
	}),
	watch: {
		pagination: function () {
			this.get();
		},
		batch: function () {
			this.get();
		},
		search(val) {},
	},
	computed: {
		...mapState(["batch", "grade"]),
	},
	mounted() {
		this.getIdCardDesignName();
	},
	methods: {
   
		get(params) {
			this.loading = true;
			let extraParam = "sectionId=" +
        this.grade.section.selected.toString() +
        "&search=" +
        this.search.toString().replace(" ", "-") +
        "&gradeId=" +
        this.grade.grade.selected.toString();

			let query = [null, undefined].includes(params)
				? this.queryString(extraParam)
				: params;
			if (this.grade.section.selected) {
				this.form.get(null, query).then(({ data }) => {
					let $this = this;
					this.form.items.data = data.data.map(function (item) {
						item.edit_roll = false;
						return item;
					});
					this.pagination.totalItems = data.meta.total;
					this.updateState = !!data.update;
					this.loading = false;
				});
			}
		},
		clearFilter() {
			this.grade.grade.selected = "";
			this.grade.section.selected = "";
			this.form.items.data = [];
			this.get();
		},
		printBulk() {
			//http://reporting.test/print/card/identity-card/1:24
			let qryString =
          "gradeId=" +
          this.grade.grade.selected +
          "&sectionId=" +
          this.grade.section.selected +
          "&validTill=" +
          this.valid_date.dateForm() +
          "&id_card_template_id=" +
          this.id_card_template_id;

			this.validateDateDialogue = false;
			redirectDownloadUrl({
				uri: "/print/card/identity-card",
				queryString:qryString,
			});
			// this.$rest.get("/api/print/student-id-card").then(({ data }) => {
			//   console.log(data.data.download_url);
			// this.validateDateDialogue = false;
			//   let url =
			//     data.data.download_url +
			//     "?gradeId=" +
			//     this.grade.grade.selected +
			//     "&sectionId=" +
			//     this.grade.section.selected +
			//     "&validTill=" +
			//     this.valid_date.dateForm() +
			//     "&id_card_template_id=" +
			//     this.id_card_template_id;
			//   window.open(url);
			// });
		},

		//update
		printSingle() {
			let batch_data = JSON.parse(localStorage.getItem("batch"));
			this.validateDateDialogue = false;
			// http://reporting.test/print/card/identity-card/1:24

			let qryString =
          "gradeId=" +
          this.grade.grade.selected +
          "&sectionId=" +
          this.grade.section.selected +
          "&validTill=" +
          this.valid_date.dateForm() +
          "&enrollId=" +
          this.selected_enroll_code +
          "&id_card_template_id=" +
          this.id_card_template_id+
          "&is_hs="+batch_data.is_hs;

			redirectDownloadUrl({
				uri: "/print/card/identity-card",
				queryString:qryString,
			});
			// this.$rest.get("/api/print/student-id-card").then(({ data }) => {
			// this.validateDateDialogue = false;
			//   let url =
			//     data.data.download_url +
			//     "?gradeId=" +
			//     this.grade.grade.selected +
			//     "&sectionId=" +
			//     this.grade.section.selected +
			//     "&validTill=" +
			//     this.valid_date.dateForm() +
			//     "&enrollId=" +
			//     this.selected_enroll_code +
			//     "&id_card_template_id=" +
			//     this.id_card_template_id+
			//     "&is_hs="+batch_data.is_hs;
			//   window.open(url);
			// });
		},
		printStudentCard(id=null){
			this.validateDateDialogue = true
			if(id){
				this.selected_enroll_code = id
				this.print_status = 'single'
			}else{
				this.print_status = 'bulk'
			}
		},
		getIdCardDesignName() {
			this.$rest
				.get("api/get-id-card")
				.then(({ data }) => {
					let default_card = {
						id:'',
						id_card_template_id:'',
						name:'Default'
					}
					data.push(default_card);
					this.card_designs = data.map((item) => {
						if (item.id_card_template_id === "1") {
							this.defaultDesign = item;
						}
						return {
							value: item.id_card_template_id,
							name: item.name.substr(0, 10) + "..",
							selected: false,
						};
					});
					this.id_card_template_id = this.defaultDesign.id_card_template_id;
					this.card_designs = this.card_designs.map((item) => {
						if (item.value === this.defaultDesign.id_card_template_id) {
							item.selected = true;
						}
						return item;
					});
				})
				.catch((error) => {});
		},
		selectedCardDesign(item) {
			if(item.value != this.id_card_template_id){
				this.id_card_template_id = item.value;
			}else{
				this.id_card_template_id = '';
			}
		},
	},
};
</script>
<style scoped>
.v-btn:not(.v-btn--round).v-size--small {
  margin-left: 5px;
}
.inputLabelabsent {
  background: #e22712;
  color: white;
}

.inputLabelLeave {
  background: #29b6f6;
  color: white;
}

.inputLabelLate {
  background: #ffb74d;
  color: black;
}

.inputRadio {
  display: none;
}
</style>
